<template>
  <div v-if="location">
    <modal-edit-description
      v-if="showEditDescription"
      :showModal="showEditDescription"
      :onClickCancel="hideModal"
    />

    <ui-section-header :showEdit="true" :editFunction="openDescription">
      <template v-slot:title>Publication descriptions</template>
    </ui-section-header>

    <transition name="fade" mode="out-in">
      <div>
        <div key="ready" class="columns">
          <div
            v-for="(textLanguage, index) in location.Descriptions"
            :key="'text_' + index"
            class="column"
          >
            <div class="title is-5">
              {{ $t('LanguageLabels.Language' + textLanguage.LanguageId) }}
            </div>
            <div
              class="wrapper-description has-padding content"
              v-html="textLanguage.Description"
            ></div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import locationProvider from '@/providers/location'
//import TextPlaceholder from '@/components/UI/TextPlaceholder'

const ModalEditDescription = () =>
  import('@/components/Locations/ModalEditDescription')

export default {
  components: {
    'modal-edit-description': ModalEditDescription,
    //TextPlaceholder,
  },

  props: {},

  data() {
    return {
      showEditDescription: false,
    }
  },

  computed: {
    ...mapState('locationStore', ['location']),
  },

  methods: {
    ...mapMutations('locationStore', ['setLocation']),

    openDescription() {
      this.showEditDescription = true
    },

    hideModal() {
      this.showEditDescription = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/template';
.columns {
  .column {
    .title-wrapper {
      height: 30px;
      border-bottom: 1px solid $grey-lighter;
    }
    .wrapper-description {
      border: 1px solid $grey-lighter;
      overflow-y: scroll;
      width: 100%;
      height: 400px;
    }
  }
}
</style>
